.contact-cards-main-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0% 5%;
  gap: 10px;
  margin-top: 2%;
}
.contact-first-card {
  border: 2px solid #979393;
  border-radius: 20px;
  background-color: #fff;
  min-height: 400px;
  height: max-content;
  width: 350px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.contact-card-heading {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.contact-first-card-para {
  color: #000;
  text-align: center;
}

.contact-second-card-detail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.contact-second-card-details {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.contact-second-card-contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-second-card-details-heading {
  font-size: 15px;
  font-weight: 400;
  color: #000;
}

.contact-third-card-emails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-card-first-image,
.contact-card-second-image,
.contact-card-third-image {
  height: 100px;
  width: 100px;
  text-align: center;
}
.contact-card-image-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.contact-second-card-image-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.contact-us-card-conatiner-react-icons {
  font-size: 15px;
  padding-right: 10px;
  color: #000;
}

.contact-third-card-emails-name {
  font-size: 16px;
  font-weight: 400;
}
@media (max-width: 768px) {
  .contact-first-card {
    max-height: max-content;
    min-height: 280px;
  }
}
