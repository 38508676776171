.about-us-banner-container {
  background-image: url("../../Images/banner1.png");
  height: 400px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-us-banner-container-heading {
  font-size: 36px;
  font-weight: 800;
  color: #fff; /* Text color is set to white */
  line-height: 20px;
}

.about-us-sub-main-container {
  display: flex;
  padding: 0% 5%;
}
.about-us-law-associates-section {
  width: 60%;
  padding: 2% 5%;
}

.about-us-law-areaofpractice-section {
  width: 40%;
  padding: 2% 5%;
}

.about-us-law-associates-section-heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 28px;
}

.about-us-law-associates-section-conatiner {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.about-us-law-associates-section-conatiner-element {
  display: flex;
  align-items: center;
}

.about-us-arrow-react-icon {
  font-size: 20px;
  color: #196ae5;
  padding-right: 10px;
}

.about-us-law-associates-section-conatiner-desc {
  font-size: 16px;
  font-size: 400;
  margin: 0px;
}

.about-us-law-areaofpractice-section-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.about-us-law-areaofpractice-section-heading {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.about-us-law-areaofpractice-section-link {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: #827e7e;
  display: flex;
  align-items: center;
  gap: 8px;
}

.about-us-law-areaofpractice-section-link:hover {
  color: #000;
}

@media (max-width: 768px) {
  .about-us-sub-main-container {
    display: flex;
    flex-direction: column;
  }

  .about-us-law-associates-section {
    width: 100%;
  }

  .about-us-law-areaofpractice-section {
    width: 100%;
  }
  .about-us-banner-container-heading {
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    padding: 3%;
  }
  .about-us-law-areaofpractice-section-heading {
    font-size: 18px;
  }
  .about-us-banner-container {
    background-image: url("../../Images/mobliebanner4.png");
    height: 300px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
