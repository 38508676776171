.contact-us-banner-container {
  background-image: url("../../Images/banner3.png");
  height: 400px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-us-banner-container-heading {
  font-size: 36px;
  font-weight: 800;
  color: #fff; /* Text color is set to white */
  line-height: 20px;
}

@media (max-width: 768px) {
  .contact-us-banner-container-heading {
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    padding: 3%;
  }
  .contact-us-banner-container {
    background-image: url("../../Images/mobliebanner4.png");
    height: 300px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
