.article-law-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: lightgrey;
  width: 100%;
  padding: 15px 0px;
}

.article-law-container-heading {
  font-size: 32px;
  font-weight: 600;
  margin: 10px 0px;
}
.latest-law {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}
.law-blog-wp-content {
  width: 100%;
  height: 350px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.associate-article-card {
  background-color: white;
  width: 50%;
  border-radius: 20px;
  gap: 10px;
}

@media (max-width: 767px) {
  .associate-article-card {
    width: 75%;
  }
}
.article-btn {
  text-align: center;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 800;
  color: white;
  background-color: #0084e9;
  border-radius: 5px;
  border-width: 0px;
  width: 120px;
  height: 40px;
  padding: 5px;
  margin: 10px;
  cursor: pointer;
}
.article-btn:hover {
  background-color: #292dc2;
}
