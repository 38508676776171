.area-ofpractice-banner-container {
  background-image: url("../../Images/banner2.png");
  height: 400px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.area-ofpractice-banner-container-heading {
  font-size: 36px;
  font-weight: 800;
  color: #fff; /* Text color is set to white */
  line-height: 20px;
}

.area-of-practice-main-container {
  display: flex;
  padding: 2% 5%;
}

.area-of-practice-sub-conatiner {
  display: flex;
  flex-direction: column;
  width: 35%;
}

.area-of-practice-sub-conatiner-links-conatiner {
  display: flex;
  flex-direction: column;
}

.about-us-law-area-of-practice-section-link {
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  color: #827e7e;
}

.about-us-law-area-of-practice-section-link:hover {
  color: #000;
}

.area-of-practice-section-full-desc-container {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.area-of-practice-section-full-desc-container-heading {
  font-size: 32px;
  font-weight: 600;
}

.area-of-practice-section-full-desc-container-desc {
  font-size: 16px;
  font-weight: 400;
  color: #333;
  padding-left: 3%;
}

@media (max-width: 768px) {
  .area-of-practice-main-container {
    flex-direction: column;
    gap: 14px;
  }
  .area-of-practice-section-full-desc-container {
    width: 100%;
  }
  .area-of-practice-sub-conatiner {
    width: 100%;
  }
  .about-us-law-area-of-practice-section-link {
    font-size: 16px;
  }
  .area-of-practice-section-full-desc-container-heading {
    font-size: 18px;
  }
  .area-of-practice-section-full-desc-container-desc {
    font-size: 14px;
  }
  .area-ofpractice-banner-container-heading {
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    padding: 3%;
  }
  .area-ofpractice-banner-container {
    background-image: url("../../Images/mobliebanner4.png");
    height: 300px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
