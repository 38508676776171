.nav-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0052cc;
  /*background-color: #9bbbeb;*/
  position: sticky;
  top: 0px;
  padding: 0% 5%;
  z-index: 3;
}
@media screen and (max-width: 768px) {
  .nav-header {
    flex-direction: column;
    align-items: center;
    border-bottom-style: none;
  }
}

.nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  background-color: #0052cc;
}
@media (max-width: 767px) {
  .nav-content {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.nav-bar-mobile-logo-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .nav-bar-mobile-logo-container {
    display: none;
  }
}

.nav-bar-large-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .nav-bar-large-container {
    display: none;
  }
}

.nav-website-logo {
  height: 50px;
  width: 120px;
}
@media screen and (max-width: 768px) {
  .nav-website-logo {
    width: 150px;
  }
}

.nav-mobile-btn {
  background-color: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
  outline: none;
  color: #fff;
}

.nav-mobile-menu-icon {
  font-size: 24px;
}

.nav-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  list-style-type: none;
  margin: 0px;
}
.nav-menu-item {
  font-weight: 600;
  cursor: pointer !important;
}

.nav-link {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  cursor: pointer !important;
}

@media (max-width: 767px) {
  .nav-items-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
  }
  .nav-menu {
    padding-left: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 5px;
  }
  .nav-close-button {
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 20px;
    color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .nav-mobile-logo {
    width: 80px;
  }
}

.active {
  color: #000;
}
