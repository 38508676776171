.khanna-law-man-container {
  background-image: linear-gradient(to bottom, #0084e9 60%, #292dc2);
  min-height: 500px;
  max-height: max-content;
  display: flex;
  justify-content: center;
  gap: 24px;
  width: 100%;
  max-width: fit-content;
  padding: 3% 5% 3% 5%;
}
/*#7d838a*/
.khanna-law-man-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  width: 50%;
}
.khanna-law-welcome-container {
  color: white;
  font-size: 32px;
  margin: 0px;
  font-weight: 600;
}
.khanna-law-ngo-container {
  color: white;
  font-size: 16px;
  margin: 0px;
  line-height: 28px;
  margin-bottom: 20px;
}

.khanna-law-button-conatiner {
  display: flex;
  gap: 10px;
}
.area-khanna-law-btn {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: transparent;
  border-radius: 5px;
  border-width: 1px;
  border-color: white;
  border-style: solid;
  cursor: pointer;
  padding: 11px 28px;
  width: max-content;
}
.area-khanna-law-btn:hover {
  /*background-color: #0084e9;*/
  background-color: #fff;
  border: none;
  color: #222;
}

.khanna-law-image-conatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.khanna-law-image-conatiner-image {
  width: 100%;
  padding: 10px;
}

.law-khanna-area-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.law-khanna-area1 {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: #232323;
  margin: 20px 0px;
}

.law-khanna-area-images {
  height: 100px;
  width: 140px;
}
.law-khanna-cards-container {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  height: 250px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  border-radius: 20px;
  padding: 15px;
  gap: 14px;
  background-color: lightgrey;
  transition: transform 0.1s;
}

.law-khanna-cards-container:hover {
  background-color: #b3b3ff;
  transform: scale(1.03);
  z-index: -999;
}

.law-khanna-area1-images {
  height: 100px;
  width: 100px;
  background-color: #0084e9;
  border-radius: 50%;
}

.law-khanna-area-heading {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}

.law-khanna-peoples-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px;
  min-height: 220px;
  height: max-content;
  width: 200px;
  gap: 5px;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 1%;
  transition: transform 0.1s;
}
.law-khanna-peoples-container:hover {
  transform: scale(1.03);
  z-index: -999;
}
.law-associate-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.law-khanna-key-article-container {
  display: flex;
  flex-direction: column;
  margin: 0px;
}

.home-page-lawAssociates-name {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
}

.home-page-lawAssociates-profession {
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
  text-align: center;
}

.home-page-lawAssociates-location {
  font-size: 14px;
  font-weight: 350;
  margin: 0px;
  text-align: center;
}
@media (max-width: 768px) {
  .khanna-law-welcome-container {
    font-size: 24px;
  }
  .khanna-law-ngo-container {
    font-size: 14px;
    line-height: 20px;
  }
  .khanna-law-man-sub-container {
    width: 80%;
  }
  .law-khanna-cards-container {
    min-width: 250px;
    max-width: 300px;
    height: 200px;
  }
  .law-khanna-area1 {
    font-size: 24px;
  }
}
@media (max-width: 512px) {
  .khanna-law-welcome-container {
    font-size: 24px;
  }
  .khanna-law-ngo-container {
    font-size: 12px;
  }
  .khanna-law-man-sub-container {
    width: 90%;
  }
  .law-khanna-cards-container {
    min-width: 150px;
    max-width: 200px;
    height: 250px;
  }
  .khanna-law-man-container {
    flex-direction: column;
    align-items: center;
    padding: 2% 0% 2% 0%;
  }
}
