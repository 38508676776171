@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap");
.course-main-container {
  flex-grow: 1;
}

.Contact-us-legal-consult-main-container {
  padding: 2% 5%;
}
.course-tab-content-container {
  display: flex;
  background-color: #fff;
  gap: 30px;
  margin: 50px;
}

.course-tab-list {
  width: 40%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.course-tab-list .course-tab-list-item {
  cursor: pointer;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 20px 20px;
  background-image: linear-gradient(to bottom, #292dc2, #0084e9);
  margin-right: 5px;
  box-shadow: rgba(173, 216, 230, 0.25) 0px 50px 100px -20px,
    rgba(173, 216, 230, 0.3) 0px 30px 60px -30px,
    rgba(173, 216, 230, 0.35) 0px -2px 6px 0px inset;
  border-radius: 5px;
  color: #fff;
}
.course-tab-list .course-tab-list-item:hover {
  box-shadow: rgba(173, 216, 230, 0.25) 0px 50px 100px -20px,
    rgba(173, 216, 230, 0.3) 0px 30px 60px -30px,
    rgba(173, 216, 230, 0.35) 0px -2px 6px 0px inset;
  color: #fff;
  background-color: #292dc2;
  transform: translate3d(10px, 0px, 20px);
}

.course-active-tab {
  /*color: #000;
  position: relative;
  background-color: #fff !important;
  box-shadow: rgba(173, 216, 230, 0.25) 0px 50px 100px -20px,
    rgba(173, 216, 230, 0.3) 0px 30px 60px -30px,
    rgba(173, 216, 230, 0.35) 0px -2px 6px 0px inset;*/
  background-color: black !important;
}

.course-content-display {
  padding: 0px 20px 20px 20px;
  background-color: #fff;
  flex-grow: 1;
  border-radius: 5px;
}
.course-List-Topic-heading {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
}
.course-Content-list-item {
  font-size: 16px;
  font-family: "Roboto";
  color: #fff;
  list-style-type: none;
}
.course-accordion-content-display {
  background-color: #fff;
}

/* Media Query for Mobile View */
@media (max-width: 767px) {
  .course-tab-content-container {
    flex-direction: column;
  }

  .course-tab-list {
    flex-wrap: wrap;
    gap: 20px;
    min-height: 514px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  }

  .course-tab-list .course-tab-list-item {
    flex-grow: 1;
    margin-right: 0;
    margin-bottom: 5px;
  }

  .course-content-display {
    margin-top: 10px; /* Add some space between tabs and content in mobile view */
  }
  .course-accordion {
    display: flex;
    flex-direction: column;
  }
  .course-accordion .course-accordion-header {
    cursor: pointer;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 700;
    padding: 20px 20px;
    background-image: linear-gradient(to bottom, #292dc2, #0084e9);
    margin-right: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    border-radius: 5px;
    margin-bottom: 10px;
    color: #fff;
    text-transform: uppercase;
  }
  .course-accordion-header:hover {
    box-shadow: rgba(255, 183, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(250, 187, 140, 0.35) 0px -2px 6px 0px inset,
      rgba(204, 85, 0, 0.4) 0px 0px 10px 0px;
    color: #fff !important;
    background-color: #fff2e6 !important;
  }
  .course-accordion:hover {
    color: #fff;
    background-color: #fff;
  }
  .course-active-accordion {
    color: #fff;
    position: relative;
    background-color: #fff !important;
  }
  .course-accordion-content {
    padding: 20px;
    background-color: #fff;
    flex-grow: 1;
    margin-bottom: 10px;
  }
}
.course-content-heading {
  font-size: 16px;
  font-weight: 600;
  color: #222;
  padding: 0px;
  margin: 0px;
}
.course-content-sub-heading {
  font-size: 16px;
  color: grey;
  padding: 0px;
  margin: 0px;
}
.course-content-items-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px;
}
.course-content-list-icon {
  font-size: 24px;
  color: #292dc2;
}
.course-content-horizontal-line {
  border: 1px solid #222;
  border-style: dashed;
  margin: 8px;
}
.course-content-list-item-sub-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.Contact-us-legal-consult-container {
  margin-top: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Contact-us-legal-consult-heading {
  font-size: 24px;
  font-weight: 600;
}
.Contact-us-legal-consult-sub-heading {
  font-size: 16px;
  text-align: center;
}
.course-accordion-content-list-container {
  list-style-type: none;
  padding: 0px;
}
