.footer-main-container {
  display: flex;
  background-color: #002966;
  padding: 3% 5%;
}

.footer-desc-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-logo {
  width: 140px;
}
.footer-desc-conatiner-description {
  font-weight: 300;
  font-size: 16px;
  color: #fff;
}

.footer-logo-conatiner {
  display: flex;
}
.footer-logo-section-container {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-logo-conatiner-logo {
  color: #fff;
  font-size: 30px;
}
.footer-logo-conatiner-logo:hover {
  color: #827d7d;
}

.footer-quick-link-conatiner {
  width: 25%;
  padding: 0% 5%;
}

.footer-conatiner-links-conatiner {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-arrow-logo {
  font-size: 14px;
  padding-right: 5px;
}

.footer-quick-link-anchor {
  font-size: 16px;
  text-decoration: none;
  color: #fff;
}

.footer-quick-link-anchor:hover {
  color: #7f9ed8;
}

.footer-quick-link-container-heading {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.footer-get-in-touch-container {
  width: 25%;
  padding: 0% 5%;
}

.footer-conatiner-get-in-touch-conatiner {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-get-in-touch-links {
  display: flex;
}

.footer-get-in-touch-links-icons {
  padding-right: 10px;
  color: #fff;
  font-size: 20px;
}

.footer-get-in-touch-links-text {
  font-size: 14px;
  margin: 0px;
  color: #fff;
}

@media (max-width: 768px) {
  .footer-main-container {
    flex-direction: column;
    gap: 20px;
  }
  .footer-desc-container {
    width: 100%;
  }

  .footer-quick-link-conatiner {
    width: 100%;
    padding: 0%;
  }

  .footer-get-in-touch-container {
    width: 100%;
    padding: 0%;
  }

  .footer-get-in-touch-links-icons {
    font-size: 30px;
  }
}
.footer-quick-link-horizontal-line {
  border: 0.5px solid #fff;
  margin: 8px 4px;
}
